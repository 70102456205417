.footer_container {
    display: flex;
    align-items: flex-end;
    font-size: small;
    font-weight: 500;
}
.footer_container p {
    width: 100%;
    padding: 7px;
    border-radius: 7px;
    background-color: var(--color-bg-variant);
}