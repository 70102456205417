.results {
    margin: 1rem; 
   max-width: 100%;
}
#county {
  text-transform: uppercase;
  font-weight: 700;
}
.stats {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 0 auto;
  border: 2px solid var(--color-primary);
  min-height: 75px;
}
.property_info {
  width: 100%;
}

.property_stats {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 0 auto;
  border: 2px solid var(--color-primary);
  min-height: 75px;
}
.zillow_stats {
  background-color: var(--color-bg-variant);
  font-size: 14px;
  margin: 7px;
  text-align: center;
  padding: 7px;
  min-height: 75px;
}

.logo {
  height: 30px;
  width: 30px;
}
.indv_stats {
  background-color: var(--color-bg-variant);
  font-size: 14px;
  margin: 7px;
  text-align: center;
  padding: 7px;
}

.tax_history {
 width: 100%;
}

.tax_year, .tax_heading {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 0 auto;
  border: 2px solid var(--color-primary);
  min-height: 45px;
}

.price_heading {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 0 auto;
  border: 2px solid var(--color-primary);
  min-height: 45px;
}
.price_year, .near_by_homes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 0 auto;
  border: 2px solid var(--color-primary);
  min-height: 45px;
}
.tax_stats, .price_stats, .neighbor {
  background-color: var(--color-bg-variant);
  font-size: 14px;
  margin: 7px;
  text-align: center;
  padding: 7px;
  min-height: 35px;
}


/* 
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #13032d;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
  }
   */

.loader_container {
    margin: 1rem 2rem 2rem 1rem; 
    display: flex;
    justify-content: center;
    flex-direction: row;
}
   .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--color-primary);
    border-bottom: 16px solid (--color-primary);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


/* Media queries (tablets) */
@media screen and (max-width: 1024px) {
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1rem;
  }
  p {
    font-size: 13px;
  }
 .stats {
  grid-template-columns: repeat(4, 1fr);
  width: 90%;
 }
 .property_stats {
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
 }
 .tax_stats p {
  font-size: 13px;
 }
 .zillow_stats p {
  font-size: 13px;
 }
 .tax_heading {
  font-size: 13px;
 }
 .tax_stats, .price_stats, .neighbor {
  font-size: 13px;
 }
 .neighbor p {
  font-size: 13px;
 }
}

/* Media queries (phones) */
@media screen and (max-width: 600px) {
  h2 {
    font-size: 1rem;
  }
  h3 {
    font-size: 0.75rem;
  }
  p {
    font-size: 10px;
  }
  .stats {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
   }
   .property_stats {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
   }
   .near_by_homes {
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
     }
     .tax_stats p {
      font-size: 10px;
     }
     .zillow_stats p {
      font-size: 10px;
     }
     .tax_heading {
      font-size: 10px;
     }
     .tax_stats, .price_stats, .neighbor {
      padding: 3px;
      margin: 3px;
      font-size: 10px;
     }
     .neighbor p {
      font-size: 10px;
     }
   }
   
  
