@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #ece4de;
  --color-bg-variant: #D8E3DD;
  --color-primary: #417490;
  --color-primary-variant: #C26C49;
  --color-white: white;
  --color-light: #f8bf9e;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}
/* choose image design7 for black effect */
body {
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-primary);
  line-height: 1.7;
  margin: 0;
  padding: 0;
}

/* General Styles */
#app {
  display: grid;
  justify-items: center;
  /* height: 100%; */
}
.main {
  max-width: 1200px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1ft auto 1fr;
  margin: 1rem 0.5rem 0 0.5rem;
}

.title {
  grid-column: 1/-1;
}

.wrapper {
  grid-column: 1/-1;
  max-width: 1200px;
}

.about {
  grid-column: 1/-1;
  max-width: 1200px;
  margin-left: 1rem;
}
/* 
.modal {
  max-width: 1200px;
} */

/* .results {
  max-width: 1200px;
  grid-column: 1/-1;
} */

.footer_container {
  grid-column: 1/-1;
   grid-row: 5;
  max-width: 1200px;
  /* max-height: 1000px; */
}

/* Media queries (tablets) */
@media screen and (max-width: 1024px) {
  .main {
    width: var(--container-width-md);
    margin: 1rem;
  }

  .wrapper {
    max-width: 1000px;
  }
  .about {
    margin-left: 0.5rem;
  }
}

/* Media queries (phones) */
@media screen and (max-width: 600px) {
  #app {
    width: 100%;
  }
  .main {
    width: var(--container-width-sm);
    justify-items: center;
  }
  .about {
    /* max-width: 338px; */
    margin-left: 0.5rem;
  }
  .title {
  padding-top: 10px;
  }
 
  .wrapper {
    max-width: 370px;
  }
 
}


/* ---------------------------------------------- */

/* 
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h2 {
  font-size: 2.5rem;
}
section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-primary);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}
.text-light {
  color: var(--color-primary);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-primary);
}
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-light);
  color: var(--color-primary);
  border-color: 1px solid var(--color-primary);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
} */
/* 
img {
  display: block;
  width: 100%;
  object-fit: cover;
} */



/* body {
  background: #e8e7e7;
  font-family: Arial, Helvetica, sans-serif;
  color: #33668d;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #13032d;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
} */

/* 
.header {
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6498b8;
  border-bottom: solid 2px #bcd2f8;
  border-radius: 6px;
  letter-spacing: 1.5px;
} */

/* 
.note {
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6498b8;
  border-bottom: solid 2px #bcd2f8;
  border-radius: 6px;
  letter-spacing: 1px;
}
.main {
  background: #fff;
  width: 90%;
  align-items: center;
  margin: auto;
  padding: auto;
  box-shadow: 0 2px 0 #6498b8;
  border-radius: 6px;
}
.modal {
  padding: 30px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-transform: uppercase;
}
.stats {
  display: flex;
  flex-direction: row;
}
.zillow_stats {
  text-decoration: none;
}
.image-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.image-and-text {
  padding: 30px 30px 30px 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.image {
  flex-grow: 1;
  width: 699px;
  /* width: 65%; 
}

.img {
  width: 700px;
  height: 600px;
}
.logo {
  width: 75px;
  height: 75px;
}

.zillow_logo {
  width: 30px;
  height: 30px;
}

.description {
  color: #33668d;
  padding-top: 200px;
  padding-right: 30px;
  padding-inline-start: 0;
  flex-grow: 1;
  width: 35%;
  font-weight: bold;
  font-size: large;
}

header h1 {
  display: flex;
  font-size: 1.5em;
  letter-spacing: 2px;
  font-weight: normal;
  text-transform: uppercase;
  font-weight: bolder;
}
*/
