.outer-carousel {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin: 1em 1em auto; */
  margin: 10px;
  justify-content: normal;
  height: 100%;
}
.image-container {
  color: var(--color-font);
  justify-content: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nonwrap;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: scroll;
  scroll-behavior: smooth;
  row-gap: calc(10%);
  gap: calc(0.7%) calc(0.5%);
  position: relative;
}

.image {
  height: 20rem;
  width: 30rem;
  object-fit:cover;
  border-radius: 5px;
}


.image:hover {
cursor: pointer;
}

.arrowRight:hover,
.arrowLeft:hover {
  color: var(--color-primary-variant);
}

.arrowLeft,
.arrowRight {
  z-index: 0;
  position: relative;
  height: 50px;
  width: 50px;
  align-self: center;
  cursor: pointer;
  color: var(--color-primary)


}
/* ////////////////////////////////////////// */

/* The Modal (background) */
.exp-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (Image) */
  .exp-modal-content {
    margin: auto;
    display: block;
    width: 90%;
    max-width: 800px;
  }
  

  
  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }

  /* Media queries (tablets) */
@media screen and (max-width: 1024px) {
.image {
  height: 15rem;
  width: 23rem;
}
 }
 
 /* Media queries (phones) */
 @media screen and (max-width: 600px) {
  .image {
    height: 12rem;
    width: 20rem;
  }
 }